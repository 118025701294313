<template>
  <div class="sbs-auth-login">
    <div class="logo-wrap">
      <sbs-logo />
    </div>

    <form @submit.prevent="onSubmit" action="">

      <input type="submit" style="display: none;" />

      <sbs-control-input v-if="showServer" v-model="server" type="text" label="Адрес сервера"
        :mask="{ mask: 'U*', tokens: { U: { pattern: /[0-9a-zA-Z\.]/ } } }" :required="true" :showRequired="false" />

      <sbs-control-input v-model="login" type="text" label="Логин" :required="true" :showRequired="false" />

      <sbs-control-input v-model="password" :type="showPassword ? 'text' : 'password'" label="Пароль" :required="true"
        :showRequired="false">
        <template v-slot:icon>
          <div v-if="!showPassword" class="icon" @click="onShowPasswordClick">
            <span class="fi fi-eye"></span>
          </div>
          <div v-else class="icon" @click="onHidePasswordClick">
            <span class="fi fi-eye-close"></span>
          </div>
        </template>
      </sbs-control-input>

      <br />

      <div class="btn btn1 btn-block" :class="loading ? 'wait' : ''" v-ripple.300 v-on:click="onSubmit">
        Войти
        <div v-if="loading" class="btn-loader"></div>
      </div>

    </form>

    <br />

    <div class="toggle-server">
      <div class="a" v-if="!showServer" @click="toggleServer">Указать сервер</div>
      <div class="a" v-else @click="toggleServer">Сервер по умолчанию</div>
    </div>
  </div>
</template>

<script>
import sbsControlInput from "@/components/controls/input";
import sbsLogo from "@/components/logo";

import bus from "@/plugins/bus";
import validator from "@/plugins/validator";
import rest from "@/plugins/rest";
import localStorage from "@/plugins/localStorage";

export default {
  name: "sbs-auth-login",
  components: {
    sbsControlInput,
    sbsLogo,
  },
  data() {
    return {
      server: "",
      login: "",
      password: "",
      loading: false,
      showPassword: false,
      showServer: false
    };
  },

  methods: {
    onSubmit: function () {
      //првоерка введённых данных
      if (!validator.check({ container: this.$el })) {
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      let server = this.showServer ? this.server : process.env.VUE_APP_DEFAULT_SERVER;

      //запрашивает автооризацию
      rest
        .call(
          "auth.login",
          {
            method: "post",
            data: {
              login: this.login,
              password: this.password,
            },
          },
          false,
          "https://" + server + "/app/sbs/rest/"
        )
        .then((data) => {
          //если ошибка входа
          if (!data.success) {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          } else {
            //обработка успешного входа
            this.onSuccess(data, server);
          }

          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Обработка успехного входа
     */
    onSuccess: function (data, server) {
      let connection = {
        server: server,
        login: this.login,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      };

      localStorage.set("last_server", server);
      localStorage.set("last_login", this.login);

      //сохраняем подключение в текущем состоянии
      this.$store.commit("setConnection", connection);

      //событие завершения входа пользователя
      bus.emit("SBS_APP_E_USER_LOGIN");

      this.password = "";
    },

    /**
     * Клик по иконке "показать пароль"
     */
    onShowPasswordClick() {
      this.showPassword = true;
    },

    /**
     * Клик по иконке "скрыть пароль"
     */
    onHidePasswordClick() {
      this.showPassword = false;
    },

    /**
     * Показать/скрыть сервер
     */
    toggleServer() {
      this.showServer = !this.showServer
    }
  },

  created() {
    let lastServer = localStorage.get("last_server");
    if (lastServer) {
      this.server = lastServer;
    }

    let lastLogin = localStorage.get("last_login");
    if (lastLogin) {
      this.login = lastLogin;
    }
  },
};
</script>

<style lang="scss">
.sbs-auth-login {
  .logo-wrap {
    width: 200px;
    margin: 0px auto 110px;

    svg {
      width: 100%;

      g {
        path:first-of-type {
          fill: var(--color-logo);
        }

        path:last-of-type {
          fill: var(--color-logo-ext);
        }
      }
    }
  }

  .toggle-server {
    text-align: center;
    opacity: 0.6;
    margin-bottom: 10px;

    .a {
      border-bottom-style: dashed;
    }
  }
}
</style>