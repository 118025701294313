<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <sbs-message />
  <sbs-network-error />
</template>

<script>
import bus from "@/plugins/bus";
import localStorage from "@/plugins/localStorage";
import ws from "@/plugins/websocket"
import { mapGetters } from "vuex";

import sbsMessage from "@/components/message";
import sbsNetworkError from "@/components/network-error";

export default {
  name: "App",
  components: {
    sbsMessage,
    sbsNetworkError,
  },
  computed: {
    ...mapGetters({
      theme: "getTheme",
    }),
  },
  watch: {
    theme: function () {
      this.applyTheme();
    },
  },
  methods: {
    /**
     * Применение темы
     */
    applyTheme: function () {
      let root = document.documentElement;

      root.classList.remove("theme-" + root.dataset.theme);
      root.classList.add("theme-" + this.theme);
      root.dataset.theme = this.theme;
    },

    /**
     * Восстанавливает текущее подключение
     */
    restoreConnection: function () {
      let arConnection = localStorage.get("connection");
      if (!arConnection) {
        //переход на страницу входа
        this.$router.replace({ name: "login" });
        return;
      }

      //сохраняе5м список подключений в текущем состоянии
      this.$store.commit("setConnection", arConnection);
      //загрузим профиль пользователя
      this.$store.dispatch("loadUserProfile");
      //загрузим настройки клиента
      this.$store.dispatch("loadClientSettings");
      //регистрируем подключение по вебсокету
      ws.register();
    },

    /**
     * Восстанавливает настройки
     */
    restoreSettings: function () {
      let theme = localStorage.get("theme");
      if (theme == null) {
        theme = "dark";
      }
      let soundNotifications = localStorage.get("soundNotifications");
      if (soundNotifications == null) {
        soundNotifications = false;
      }
      //сохраняем в общем состоянии
      this.$store.commit("setTheme", theme);
      this.$store.commit("setSoundNotifications", soundNotifications);
    },

    /**
     * Обновление токена
     */
    onRefreshToken(data) {
      let arConnection = this.$store.getters.getConnection;

      //меняем в текущем подключени токены
      arConnection["access_token"] = data.access_token;
      arConnection["refresh_token"] = data.refresh_token;

      //сохраняем подключение в текущем состоянии
      this.$store.commit("setConnection", arConnection);
    },

    /**
     * Обработка входа пользователя
     */
    onLogin() {
      //загрузим профиль пользователя
      this.$store.dispatch("loadUserProfile");
      //загрузим настройки клиента
      this.$store.dispatch("loadClientSettings");
      //регистрируем подключение по вебсокету
      ws.register();

      //переход на главный экран
      this.$router.replace({ name: "home" });
    },

    /**
     * Выход пользователя
     */
    logout() {
      //закрываем подключение по вебсокету
      ws.close();
      //очищаем хранилище
      localStorage.clear();
      //сбрасываем текущее состояние
      this.$store.dispatch("clear");
      //переход на страницу входа
      this.$router.push({ name: "login" });
    },
  },

  created() {
    //восстанавливает текущее подключение
    this.restoreConnection();
    //восстанвливаем настройки
    this.restoreSettings();
  },

  mounted() {
    this.applyTheme();

    //подписваемся на событие авторизации пользователя
    bus.on("SBS_APP_E_USER_LOGIN", this.onLogin);
    //подписваемся на событие обновления токена
    bus.on("SBS_APP_E_REFRESH_TOKEN", this.onRefreshToken);
    //подписваемся на событие выхода
    bus.on("SBS_APP_E_LOGOUT", this.logout);
  },
  unmounted() {
    //отписываемся от событий
    bus.off("SBS_APP_E_USER_LOGIN", this.onLogin);
    bus.off("SBS_APP_E_REFRESH_TOKEN", this.onRefreshToken);
    bus.off("SBS_APP_E_LOGOUT", this.logout);
  },
};
</script>

<style lang="scss">
#app {
  overflow: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
