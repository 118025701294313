<template>
  <div
    class="form-group form-group-label"
    :class="[
      focused ? 'focused' : '',
      value ? 'filled' : '',
      noLabelAnimation && value ? 'no-label-animation' : '',
      !!this.$slots.icon ? 'form-group-icon' : '',
    ]"
  >
    <template v-if="label != ''">
      <div class="floating-label" v-on:click="onLabelClick">
        {{ label }}
        <span v-if="required && needShowRequired">*</span>
      </div>
    </template>
    <input
      class="form-control"
      :type="type"
      :name="name"
      :class="classes.concat([required ? 'required' : ''])"
      v-model="value"
      v-maska="mask"
      :readonly="readonly"
      @input="handleInput"
      @focus="onFocus"
      @blur="onBlur"
      ref="input"
      autocomplete="off"
      :placeholder="placeholder"
      @change="onChange"
    />

    <slot name="icon"></slot>

    <img v-if="captcha" :src="captcha" class="captcha" />

    <div v-if="description" v-html="description" class="description"></div>
  </div>
</template>

<script>
export default {
  name: "sbs-control-input",
  data() {
    return {
      value: this.modelValue,
      focused: false,
    };
  },
  emits: ["update:modelValue", "change"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: String,
      default() {
        return "";
      },
    },
    captcha: {
      type: String,
      default() {
        return "";
      },
    },
    mask: {
      type: Object,
      default() {
        return null;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRequired: {
      type: Boolean,
      default() {
        return true;
      },
    },
    description: {
      type: String,
      default() {
        return "";
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
    noLabelAnimation: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    needShowRequired() {
      return this.required && this.showRequired;
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        this.value = newVal;
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit("update:modelValue", this.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onLabelClick() {
      this.focused = true;
      this.$refs.input.focus();
    },
    onChange() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss">
</style>
