<template>
  <div class="sbs-v-login">
    <div class="container">
      <sbs-auth-login />
    </div>
  </div>
</template>

<script>
import sbsAuthLogin from "@/components/auth/login.vue";

export default {
  name: "sbs-v-login",
  components: {
    sbsAuthLogin,
  },
};
</script>

<style lang="scss">
.sbs-v-login {
  padding-top: 100px;
}
</style>
