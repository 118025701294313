<template>
  <div class="sbs-network-error" :class="open ? 'open' : ''">
    <div class="inner">
      <div class="container">
        <div class="page-padding">
          <div class="text-center">
            <svg
              width="82"
              height="60"
              viewBox="0 0 82 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41 1.69335C32.8492 1.69335 25.5705 3.13559 19.0332 5.91678C12.7337 8.59467 7.10027 12.5201 2.01789 17.6018L41 57.9489L79.9821 17.6018C74.8997 12.5201 69.2703 8.59467 62.9668 5.91678C56.4255 3.13559 49.1508 1.69335 41 1.69335ZM51.1925 35.4888H49.2777V37.4078H51.1925V35.4888ZM55.0856 37.8886H53.1708V39.8076H55.0856V37.8886ZM31.1682 35.4888H33.0831V37.4078H31.1682V35.4888ZM27.2752 37.8886H29.19V39.8076H27.2752V37.8886ZM41 12.1784C42.9386 12.1784 44.5085 13.7518 44.5085 15.6947C44.5085 17.6375 42.9386 19.2109 41 19.2109C39.0614 19.2109 37.4915 17.6375 37.4915 15.6947C37.4915 13.7518 39.0614 12.1784 41 12.1784ZM38.8196 22.1907H43.1844V39.4738H38.8196V22.1907ZM50.3203 17.7964H48.4055V19.7155H50.3203V17.7964ZM54.1539 18.6864H52.2391V20.6054H54.1539V18.6864ZM57.9875 20.1207H56.0727V22.0397H57.9875V20.1207ZM61.4485 21.4676H59.5336V23.3866H61.4485V21.4676ZM64.8817 23.8197H62.9668V25.7387H64.8817V23.8197ZM68.1166 26.001H66.2018V27.92H68.1166V26.001ZM32.4051 17.7964H34.32V19.7155H32.4051V17.7964ZM28.5716 18.6864H30.4864V20.6054H28.5716V18.6864ZM24.738 20.1207H26.6528V22.0397H24.738V20.1207ZM21.277 21.4676H23.1918V23.3866H21.277V21.4676ZM17.8438 23.8197H19.7587V25.7387H17.8438V23.8197ZM14.6089 26.001H16.5237V27.92H14.6089V26.001ZM18.379 4.36329C25.1344 1.49072 32.6272 0.000793457 41 0.000793457C49.3689 0.000793457 56.8656 1.49072 63.621 4.36329C70.3763 7.23586 76.3745 11.4831 81.7621 17.0058C82.0793 17.3316 82.0833 17.856 81.7621 18.1818L41.5867 59.7606C41.2537 60.0864 40.7225 60.0785 40.3974 59.7448L0.237865 18.1818C-0.0792883 17.856 -0.0792883 17.3356 0.237865 17.0058C5.62154 11.4871 11.6237 7.23586 18.379 4.36329Z"
                fill="white"
              />
            </svg>
          </div>

          <div class="text">Нет соединения</div>
          <div class="btns-wrap">
            <div
              class="btn btn1"
              :class="loading ? 'wait' : ''"
              v-ripple.300
              v-on:click="onRefreshClick"
            >
              Повторить
              <div v-if="loading" class="btn-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import store from "@/store";

import { mapGetters } from "vuex";

export default {
  name: "sbs-network-error",
  data() {
    return {
      open: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      online: "getOnline",
    }),
  },
  watch: {
    online: function (newVal) {
      if (newVal) {
        this.open = false;
      }
    },
  },
  methods: {
    /**
     * Показывает экран ошибки
     */
    show: function () {
      this.open = true;
    },

    /**
     * Обработка клика по кнопке "Обновить"
     */
    onRefreshClick: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      //тестируем соединение
      rest
        .isOnline()
        .then((success) => {
          if (success) {
            //если соединение появилось - закрываем экран
            this.open = false;
            store.commit("setOnline", true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    //подписываемся на событие ошибки сети
    bus.on("NETWORK_ERROR", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("NETWORK_ERROR", this.show);
  },
};
</script>

<style lang="scss">
.sbs-network-error {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000;

  display: none;

  backdrop-filter: blur(15px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background: var(--color-background);
    opacity: 0.85;
  }

  .inner {
    position: absolute;
    top: 50%;
    right: 0px;
    left: 0px;
    z-index: 2;

    transform: translateY(-50%);
  }

  &.open {
    display: block;
  }

  .text {
    text-align: center;

    margin-bottom: 55px;
  }

  .btn {
    display: block;
  }

  svg {
    margin: 0px auto 30px;

    path {
      fill: var(--color-text-primary);
    }
  }
}
</style>