import { createStore } from 'vuex'
import localStorage from '@/plugins/localStorage'

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default createStore({
  state: {
    //текущее подключение
    connection: false,
    //тема
    theme: "",
    //включить звуковые уведодмления
    soundNotifications: false,
    //профиль пользователя
    user: false,
    online: true,
    //настройки клиента
    clientSettings: false,
    //ИД соединения вебсокета
    websocketId: "",
  },
  getters: {
    getConnection(state) {
      return state.connection;
    },
    getAccessToken(state) {
      return state.connection ? state.connection.access_token : null;
    },
    getRefreshToken(state) {
      return state.connection ? state.connection.refresh_token : null;
    },
    getApiUrl(state) {
      return state.connection ? "https://" + state.connection.server + "/app/sbs/rest/" : "";
    },
    getServer(state) {
      return state.connection ? state.connection.server : "";
    },
    isAuthorized(state) {
      return state.connection ? true : false;
    },
    getTheme(state) {
      return state.theme;
    },
    getSoundNotifications(state){
      return state.soundNotifications;
    },
    getUserProfile(state) {
      return state.user;
    },
    getOnline(state) {
      return state.online;
    },
    getClientSettings(state) {
      return state.clientSettings;
    },
    getWebSocketId(state) {
      return state.websocketId;
    },
    
  },
  mutations: {
    setConnection: (state, value) => {
      //переводим на новый домен
      if(value.server == "sbs.okraina.ru") {
        value.server = "cbc1.ru";
      }
      state.connection = value;
      localStorage.set("connection", value);
    },
    setTheme: (state, value) => {
      state.theme = value;
      localStorage.set("theme", value);
    },
    setSoundNotifications: (state, value) => {
      state.soundNotifications = value;
      localStorage.set("soundNotifications", value);
    },
    setUserProfile: (state, value) => {
      state.user = value;
    },
    setOnline: (state, value) => {
      state.online = value;
    },
    setClientSettings: (state, value) => {
      state.clientSettings = value;
    },
    setWebSocketId: (state, value) => {
      state.websocketId = value;
    },

  },
  actions: {
    /**
     * Сброс данных
     */
    clear(context) {

      //очищаем подключение
      context.commit("setConnection", false);

      //сбрасываем состояние, зависящее от текущего аккаунта
      context.dispatch("reset");
    },

    /**
     * Сброс данных, зависящий от аккаунта
     */
    reset(context) {

      //сброс профиля пользователя
      context.commit('setUserProfile', false);
       //сброс настроек клиента
      context.commit('setClientSettings', false);
    },

    /**
     * Загружает профиль пользователя
     */
    loadUserProfile(context) {
      //запрашиваем данные
      rest
        .call("user.profile.get", {}, false)
        .then((data) => {
          if (data.success) {
            context.commit('setUserProfile', data.fields);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    },

    /**
     * Загружает настройки клиента
     */
    loadClientSettings(context) {
      //запрашиваем данные
      rest
        .call("system.settings", {}, false)
        .then((data) => {
          if (data.success) {
            context.commit('setClientSettings', data.settings);
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    }
  },
  modules: {
  }
})
