import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import "moment";
import "moment/locale/ru";

import Ripple from './directives/ripple/ripple'
import './directives/ripple/ripple.css'

import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import './assets/fonts/roboto/style.css'
import './assets/fonts/icomoon/style.css'
import './assets/themes/dark.scss'
import './assets/themes/light.scss'
import './assets/styles/default.scss'
import './assets/styles/forms.scss'
import './assets/styles/typography.scss'
import './assets/styles/buttons.scss'

const app = createApp(App)

//плагин маски ввода
app.use(Maska);

//плагин ряби на кнопках
Ripple.color = 'rgba(255, 255, 255, 0.35)';
app.directive('ripple', Ripple);

app.use(store).use(router).mount('#app')
