<template>
  <svg class="sbs-logo-mini" viewBox="-20 -20 573 335" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M457.009 69.3846C486.266 69.3846 512.936 80.4612 533 98.6214L509.117 124.741C495.363 112.29 477.075 104.7 457.009 104.7C435.622 104.7 416.26 113.321 402.25 127.258C388.242 141.192 379.572 160.451 379.572 181.725C379.572 202.999 388.242 222.258 402.25 236.192C416.26 250.129 435.623 258.75 457.009 258.75C477.075 258.75 495.363 251.16 509.117 238.71L533 264.829C512.936 282.989 486.266 294.066 457.009 294.066C425.828 294.066 397.59 281.488 377.153 261.158C371.385 255.421 366.238 249.066 361.82 242.202C357.403 249.066 352.257 255.421 346.49 261.158C326.051 281.488 297.811 294.066 266.629 294.066C235.449 294.066 207.211 281.488 186.773 261.158C181.005 255.422 175.858 249.066 171.441 242.202C167.023 249.066 161.877 255.422 156.109 261.158C135.67 281.488 107.433 294.066 76.2518 294.066C46.9956 294.066 20.3253 282.989 0.261688 264.829L24.1446 238.71C37.8984 251.16 56.1859 258.75 76.2522 258.75C97.6396 258.75 117.001 250.129 131.012 236.192C145.02 222.258 153.69 202.999 153.69 181.725C153.69 160.451 145.02 141.192 131.012 127.258C117.001 113.322 97.6396 104.7 76.2522 104.7C56.1859 104.7 37.8984 112.29 24.1446 124.741L0.261688 98.6214C20.3253 80.4612 46.9956 69.3846 76.2518 69.3846C106.221 69.3846 133.47 81.0045 153.689 99.9586V0C170.83 0.745773 189.192 19.109 189.192 49.2867V99.9604V181.492V181.531V181.569V181.608V181.647V181.686V181.725C189.192 202.998 197.862 222.257 211.871 236.191C225.881 250.128 245.243 258.75 266.629 258.75C288.017 258.75 307.381 250.129 321.392 236.191C335.402 222.255 344.07 202.998 344.07 181.725C344.07 160.45 335.402 141.193 321.392 127.257C309.359 115.288 293.376 107.239 275.553 105.205V69.7319C303.177 71.8813 328.021 83.921 346.49 102.292C352.257 108.029 357.403 114.384 361.82 121.248C366.238 114.384 371.385 108.029 377.153 102.292C397.591 81.9615 425.828 69.3846 457.009 69.3846Z"
      fill="white" stroke="white" stroke-width="15" />
    <path
      d="M 171.279 122.543 C 184.388 128.992 196.53 151.623 196.53 173.308 L 196.53 182.138 C 191.139 174.187 187.251 167.829 171.279 159.972 C 156.004 168.32 152.831 172.627 146.027 182.138 L 146.027 173.308 C 146.027 151.618 159.817 128.804 171.279 122.543 Z"
      fill="#F1B911" clip-path="url(#clip0_20_890)" style=""
      transform="matrix(0.999986, -0.005322, 0.005322, 0.999986, -0.808331, 0.913702)" />
  </svg>
</template>

<script>
export default {
  name: "sbs-logo-mini",
};
</script>

<style lang="scss">
.sbs-logo-mini {
  width: 100%;

  path:first-of-type {
    fill: var(--color-logo);
    stroke: var(--color-logo);
  }

  path:last-of-type {
    fill: var(--color-logo-ext);
  }
}
</style>