<template>
    <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" :title="theme == 'dark' ? 'Светлая тема' : 'Тёмная тема'"
        @click="onClick">
        <span v-if="theme == 'dark'" class="icon fi fi-light-mode"></span>
        <span v-else class="icon fi fi-dark-mode"></span>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: 'sbs-action-theme',
    computed: {
        ...mapGetters({
            theme: "getTheme",
        }),
    },
    methods: {

        /**
         * Клик по действию
         */
        onClick() {

            if (this.theme == 'dark') {

                this.$store.commit("setTheme", 'light');
            } else {
                this.$store.commit("setTheme", 'dark');
            }
        }
    }
}

</script>