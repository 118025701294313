
export default {

   check(params) {

      let inputs = params.container.querySelectorAll(".required");
      let ignore = params.ignore ? params.ignore : [];

      let hasError = false;
      for (let i = 0; i < inputs.length; i++) {
         let input = inputs[i];
         let value = input.value;
         let name = input.getAttribute("name");
         let type = input.getAttribute("type");
         let error = false;

         if (ignore.includes(name)) {
            continue;
         }

         let formGroup = input.closest(".form-group");

         //если поле переключатель
         if (type == "radio") {
            name = name.replace("[", "\\[");
            name = name.replace("]", "\\]");

            let selected = params.container.querySelectorAll("input[name='" + name + "']:checked");
            if (selected.length > 0) {
               value = params.container.querySelector("input[name='" + name + "']:checked").value;
            } else {
               value = false;
            }
         }

         if (!value) {
            error = true;
         }

         if (error) {
            formGroup.classList.add("form-group-red");
            hasError = true;
         } else {
            formGroup.classList.remove("form-group-red");
         }

      }

      return !hasError;
   }

}