<template>
  <div class="sbs-settings">
    <h2>Тема</h2>
    <div class="list">
      <div class="item" v-for="item in themes" :key="item.id">
        <div class="label">{{ item.name }}</div>
        <div class="control">
          <sbs-control-radio
            name="theme"
            :value="item.id"
            v-model="themeInternal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sbsControlRadio from "@/components/controls/radio.vue";

import { mapGetters } from "vuex";

export default {
  name: "sbs-settings",
  components: {
    sbsControlRadio,
  },
  data() {
    return {
      themeInternal: this.theme,
      themes: [
        {
          id: "dark",
          name: "Тёмная",
        },
        {
          id: "light",
          name: "Светлая",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      theme: "getTheme",
    }),
  },
  watch: {
    themeInternal: function (value) {
      if (value != this.theme) {
        this.$store.commit("setTheme", value);
      }
    },
    theme: function (value) {
      if (value != this.themeInternal) {
        this.themeInternal = value;
      }
    },
  },
  /**
   * Создание эксземпляра компонента
   */
  created() {
    this.themeInternal = this.theme;
  },
};
</script>

<style lang="scss">
.sbs-settings {
  .list {
    .item {
      position: relative;
      margin-bottom: 1.07em;

      .control {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }
  }
}
</style>