export default {

    prefix: "sbsm_",
    clearExclude: [
        "last_server",
        "last_login",
        "theme"
    ],

    set(key, value) {

        //если объект, то обязательно конвертируем в строку
        if ((typeof value === 'object') && value !== null) {
            value = JSON.stringify(value);
        }

        window.localStorage.setItem(this.prefix + key, value);
    },

    get(key) {

        let value = window.localStorage.getItem(this.prefix + key);

        if (value !== null) {
            //пытаемся конвертировать из строки в объект
            try {
                value = JSON.parse(value);
            } catch (e) {
                //to do
            }
        }

        return value;
    },

    /**
     * Очищает все данные
     */
    clear() {
        let keys = Object.keys(window.localStorage);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key.startsWith(this.prefix)) {

                let k = key.replace(this.prefix, "");

                if (!this.clearExclude.includes(k)) {
                    window.localStorage.removeItem(key);
                }
            }
        }


    }

}