export default {

    playAudio(file, volume =1) {
        let audio;

        if(!audio) {
            audio = document.createElement('audio')
        }

        var path = "/sounds/"+file;
        let player = null;

        if(audio.canPlayType('audio/mpeg')) {
            player = new Audio(path+".mp3");
        }

        if(player != null) {  
            player.volume = volume;
            let promise = player.play();
            return promise;
        }

        return false;

    },

}