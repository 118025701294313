<template>
  <div class="sbs-v-requests">
    <sbs-requests-table />
  </div>
</template>

<script>
import sbsRequestsTable from "@/components/requests/table.vue";

export default {
  name: "sbs-v-requests",
  components: {
    sbsRequestsTable,
  },
};
</script>
