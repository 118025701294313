<template>
  <div class="sbs-popup" :class="{ show: show }">
    <div class="bg" @click="close"></div>
    <div class="inner">
      <div class="close" @click="close">
        <span class="icon fi fi-close"></span>
      </div>

      <!-- Загрузка -->
      <template v-if="loading">
        <div class="loading">
          <div class="loader">
            <sbs-loader-inline />
          </div>
        </div>
      </template>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";

export default {
  name: "sbs-popup",
  emits: ["update:show"],
  components: { sbsLoaderInline },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>


<style lang="scss">
.sbs-popup {
  .bg {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1050;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    background: rgba(0, 0, 0, 0.5);
  }

  &.show {
    .bg {
      visibility: visible;

      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }

  .inner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);

    min-height: 200px;
    width: 300px;

    background: var(--color-background);

    z-index: 1100;
    visibility: hidden;

    opacity: 0;
    transition: visibility 0s linear 300ms, transform 300ms, opacity 300ms;

    padding: 55px 30px 30px;
    padding-top: 55px;

    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 2.85em;
      width: 2.85em;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-color1);
      }
    }
  }

  &.show {
    .inner {
      visibility: visible;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: visibility 0s linear 0s, transform 300ms, opacity 300ms;
    }
  }

  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    &:before {
      content: "";
      display: block;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 100;
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 200;
    }
  }
}
</style>