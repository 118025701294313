<template>
  <div class="sbs-requests-table">
    <!-- Загрузка -->
    <template v-if="loading && this.items == null">
      <div class="text-center">
        <sbs-loader-inline />
      </div>
    </template>

    <template v-else-if="items != null">
      <!-- Настройки -->
      <sbs-popup v-model:show="showSettings">
        <sbs-settings />
      </sbs-popup>

      <!-- Шапка -->
      <div class="header">
        <div class="panel">
          <div class="container-fluid h-100">
            <div class="row h-100 align-items-center">
              <div class="col-7 col-left">
                <div class="logo">
                  <sbs-logo-mini />
                </div>
                <div v-if="user" class="department">
                  {{ user.department_name }}
                </div>
              </div>
              <div class="col col-right">

                <sbs-action-sound />

                <sbs-action-theme />

                <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="toggleFullscreen">
                  <span class="icon fi fi-fullscreen"></span>
                </div>

                <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'" @click="logout">
                  <span class="icon fi fi-logout"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-table">
          <div class="table-row">
            <div class="th cell-id">№/Дата</div>
            <div class="th cell-owner">Автор</div>
            <div class="th cell-equipment">Оборудование/Проблема</div>
            <div class="th cell-responsible">Исполнитель</div>
            <div class="th cell-type">Тип</div>
            <div class="th cell-priority">Приоритет</div>
            <div class="th cell-status">Статус</div>
            <div class="th cell-views"></div>
          </div>
        </div>
      </div>

      <!-- Таблица заявок -->
      <div class="content" ref="content">
        <swiper :slidesPerView="'auto'" :loop="false" :spaceBetween="0" :direction="'vertical'" :slidesPerGroupAuto="true"
          :speed="600" @swiper="onSwiper">
          <swiper-slide v-for="item in items" :key="item.id">
            <div class="item" :ref="setItemRef" :style="{ backgroundColor: backgroundColor(item) }">
              <div class="data-table">
                <div class="table-row">
                  <div class="td cell-id">
                    <div class="id mb">#{{ item.number }}</div>

                    <div class="date">{{ formatDate(item.date_create) }}</div>
                  </div>
                  <div class="td cell-owner">
                    <div class="mb created_department">
                      {{ item.created_by_department_name }}
                    </div>
                    <div v-if="item.created_by > 0">
                      {{ item.created_by_user_name }}
                      {{ item.created_by_user_last_name }}
                    </div>
                  </div>
                  <div class="td cell-equipment">
                    <div class="equipment mb" v-if="item.equipment_id > 0">
                      {{ item.equipment_name }}
                    </div>
                    <div>
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="td cell-responsible">
                    <div class="mb">
                      {{ item.responsible_department_name }}
                    </div>
                    <div class="responsible_user" v-if="item.responsible_user_id > 0">
                      {{ item.responsible_user_name }}
                      {{ item.responsible_user_last_name }}
                    </div>
                  </div>

                  <div class="td cell-type">{{ item.type_name }}</div>
                  <div class="td cell-priority">
                    {{ item.priority_name }}
                    <span class="priority fi fi-fire" :style="{ color: item.priority_color }"></span>
                  </div>
                  <div class="td cell-status">
                    <div class="status" :style="{ backgroundColor: item.status_color }">
                      {{ item.status_name }}
                    </div>
                  </div>
                  <div class="td cell-views">
                    <div class="views" v-if="item.viewers_count > 0">
                      <span class="fi fi-eye icon"></span>
                      {{ item.viewers_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <!-- Прогресс -->
      <div class="bottom">
        <div class="progress" :style="{
          width: percent + '%',
          transition: transition ? 'width ' + delay + 'ms linear' : '',
        }"></div>
      </div>
    </template>
  </div>
</template>

<script>
import sbsLoaderInline from "@/components/loader.inline.vue";
import sbsLogoMini from "@/components/logo.mini.vue";
import sbsPopup from "@/components/popup.vue";
import sbsSettings from "@/components/settings.vue";
import sbsActionSound from "@/components/action.sound.vue";
import sbsActionTheme from "@/components/action.theme.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import moment from "moment";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

export default {
  name: "sbs-requests-table",
  components: {
    sbsLoaderInline,
    sbsLogoMini,
    Swiper,
    SwiperSlide,
    sbsPopup,
    sbsSettings,
    sbsActionSound,
    sbsActionTheme
  },
  data() {
    return {
      loading: true,
      items: null,
      itemRefs: [],
      fullscreen: false,
      swiper: false,
      index: 1,
      percent: 0,
      delay: 10000,
      autoplay: true,
      transition: false,
      showSettings: false,
      themeInternal: this.theme,
      themes: [
        {
          id: "dark",
          name: "Тёмная",
        },
        {
          id: "light",
          name: "Светлая",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUserProfile",
      theme: "getTheme",
      clientSettings: "getClientSettings",
      soundNotifications: "getSoundNotifications",
    }),

    //ИД статуса "Новая"
    requestStatusNew() {
      return this.clientSettings
        ? this.clientSettings.request_status_new
        : false;
    },

    //ИД статуса "Завершено"
    requestStatusFinished() {
      return this.clientSettings
        ? this.clientSettings.request_status_finished
        : false;
    },

    count() {
      if (!this.swiper || !this.$refs.content) {
        return 1;
      }

      let maxHeight = this.$refs.content.offsetHeight;
      let h = 0;
      let count = 1;
      this.itemRefs.forEach((i) => {
        if (h + i.offsetHeight < maxHeight) {
          h += i.offsetHeight;
        } else {
          count++;
          h = 0;
        }
      });
      if (h > 0) {
        count++;
      }

      return count;
    },
  },
  watch: {
    requestStatusFinished: function (newVal) {
      if (newVal) {
        this.loadData();
      }
    },
  },
  methods: {
    /**
     * Запоминает ссылки на элементы сообщений
     */
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },

    /**
     * Загружает данные
     */
    loadData() {
      //покажем экран загрузки
      this.loading = true;
      //скроем метку "есть новые заявки"
      this.showNewRequests = false;

      //запрашиваем данные
      rest
        .call("request.list", {
          method: "post",
          data: {
            filter: {
              "!status_id": this.requestStatusFinished,
            },
          },
        })
        .then((data) => {
          if (data.success) {
            this.items = data.items;
            if (this.swiper) {
              this.progress();
            }
          } else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });

            let vm = this;
            setTimeout(() => {
              vm.loadData();
            }, 10000);
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
        });
    },

    /**
     * Форматирует дату
     */
    formatDate(date) {
      return moment(date, "DD.MM.YYYY hh:mm:ss").format("DD MM YYYY HH:mm");
    },

    /**
     * Открывает полноэкранный режим
     */
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        this.$el.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },

    /**
     * Выход
     */
    logout() {
      bus.emit("SBS_APP_E_LOGOUT");
    },

    /**
     * Обработка инициализщации свайпера
     */
    onSwiper(swiper) {
      this.swiper = swiper;
      if (this.autoplay) {
        this.progress();
      }
    },

    /**
     * Автоплей
     */
    progress() {
      if (this.count == 1) {
        this.delay = 30000;
      } else if (this.count == 21) {
        this.delay = 20000;
      } else {
        this.delay = 10000;
      }
      this.percent = (this.index / this.count) * 100;
      this.transition = true;
      let vm = this;
      setTimeout(() => {
        if (vm.index < vm.count) {
          this.index++;
          this.swiper.slideNext();
          vm.progress();
        } else {
          vm.reload();
        }
      }, this.delay);
    },

    reload() {
      this.transition = false;
      this.index = 1;
      this.percent = 0;
      this.swiper.slideTo(0);
      this.loadData();
    },

    /**
     * Открывает настройки
     */
    openSettings() {
      this.showSettings = true;
    },

    /**
     * Получает фон для заявки
     */
    backgroundColor(item) {
      if (item.status_id == this.requestStatusNew) {
        return 'var(--color-control-background)';
      }
      return "var(--color-block-background)";
    },

    /**
     * Обработка получения сообщения о новой заявке
     */
    onMessageReceive(data) {
      //если событие создания новой заявки
      if (data.event == "new_request") {
        if (this.soundNotifications) {
          tools.playAudio("new_request");
        }
        this.loadItem(data.requestId);
      }
    },

    /**
     * Загружает заявку по ИД
     */
    loadItem(id) {
      //запрашиваем данные
      rest
        .call("request.get", {
          method: "post",
          data: {
            id: id,
          },
        })
        .then((data) => {
          //если завка найдена
          if (data.success) {
            let item = this.items.find((i) => i.id == id);
            if (item) {
              let index = this.items.indexOf(item);
              this.items[index] = data.request;

            } else {
              this.items.unshift(data.request);
            }
          }
          else {
            //показываем сообщение об ошибке
            bus.emit("SBS_MESSAGE_E_SHOW", { message: data.errorText });
          }
        });
    }
  },

  mounted() {
    //подписваемся на события
    bus.on("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },

  unmounted() {
    //отписываемся от событий
    bus.off("SBS_WS_MESSAGE_E_RECEIVE", this.onMessageReceive);
  },

  /**
   * Событие перед обновлением DOM
   */
  beforeUpdate() {
    this.itemRefs = [];
  },

  created() {
    this.loading = true;
    if (this.requestStatusFinished > 0) {
      //загружаем данные
      this.loadData();
    }

  },
};
</script>

<style lang="scss">
.sbs-requests-table {
  .header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100px;
  }

  .content {
    position: fixed;
    top: 100px;
    left: 0px;
    right: 0px;
    bottom: 10px;
    overflow: hidden;

    .swiper {
      height: 100%;
    }

    .swiper-slide {
      height: auto;
    }
  }

  .bottom {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 10px;
  }

  /**
    Панель
   */
  .panel {
    height: 50px;

    .h-100 {
      height: 100%;
    }

    .col-left {
      padding-left: 80px;
    }

    .col-right {
      text-align: right;
      line-height: 0;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-60%);
      width: 50px;
    }

    .department {
      text-transform: uppercase;
    }

    .action {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 25px;
        color: var(--color-icon-active);
      }
    }
  }

  /**
    Таблица
   */

  .data-table {
    width: 100%;
    display: table;

    .table-row {
      display: table-row;

      >div {
        display: table-cell;
        padding: 10px;
      }
    }

    .th {
      background-color: var(--color-color1);
      color: var(--color-background);
      font-weight: 600;
    }

    .td {
      color: var(--color-block-text);
      border-bottom: 1px solid var(--color-block-label);
      vertical-align: top;
      line-height: 1.1;
    }

    .cell-id {
      width: 8%;
    }

    .cell-owner {
      width: 19%;
    }

    .cell-equipment {
      width: 21%;
    }

    .cell-responsible {
      width: 21%;
    }

    .cell-type {
      width: 10%;
    }

    .cell-priority {
      width: 8%;
    }

    .cell-status {
      width: 8%;
    }

    .cell-views {
      width: 5%;
      padding-left: 0px;
    }

    .item {
      background-color: var(--color-block-background);
    }

    .id {
      color: var(--color-block-title);
    }

    .date {
      font-size: 0.7em;
      color: var(--color-text-label);
    }

    .created_department,
    .equipment,
    .responsible_user {
      color: var(--color-block-title);
    }

    .mb {
      margin-bottom: 5px;
    }

    .status {
      display: inline-block;
      top: 10px;
      right: 10px;
      padding: 6px 5px;
      min-width: 90px;
      text-align: center;
      color: #1e1f22;
      border-radius: 4px;
      line-height: 1;
      font-size: 0.7em;
    }

    .views {
      position: relative;
      font-size: 0.8em;
      padding-left: 1.5em;
      line-height: 1;

      .icon {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
      }
    }
  }

  .progress {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 0px;
    background-color: var(--color-color1);
  }
}
</style>