<template>
    <div class="action" v-ripple.300="'rgba(100, 100, 100, 0.15)'"
        :title="enabled ? 'Выключить звуковое оповещение' : 'Включить звуковое оповещение'" @click="onClick">
        <span v-if="enabled" class="icon fi fi-notifications"></span>
        <span v-else class="icon fi fi-notifications-off"></span>
    </div>
</template>

<script>
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: 'sbs-action-sound',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            enabled: "getSoundNotifications",
        }),
    },
    methods: {

        /**
         * Клик по дейсвтию
         */
        onClick() {

            if (!this.enabled) {

                //показываем сообщение об ошибке
                bus.emit('SBS_MESSAGE_E_SHOW', { message: "Включите в настройках браузера разрешение 'Звук'.", type: "success", duration: 6000 });

                this.$store.commit("setSoundNotifications", true);
            } else {
                this.$store.commit("setSoundNotifications", false);
            }
        }
    }
}
</script>